import {
  createStore
} from "vuex";
import api from "../../lib/api";

export default createStore({
  state() {
    return {
      customer: {},
    };
  },
  getters: {
    getCustomer: async (state) => {
      if (JSON.stringify(state.customer) == "{}") {
        let response = await api.get("api/customer");
        if (response.data.customer) {
          state.customer = response.data.customer;
        } else {
          state.customer = {};
          alert("Non è stato possibile recuperare il tuo account, assicurati di aver abilitato i cookie. Se il problema persiste contatta il nostro servizio clienti.");
          if (process.env.VUE_APP_ENV !== "local") {
            window.location.href = process.env.VUE_APP_STORE_URL;
          }
        }
      }
      return state.customer;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});