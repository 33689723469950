import axios from 'axios';
import {API_KEY} from "../config.js";
import CryptoJS from "crypto-js";

const api = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		'x-source-from': process.env.VUE_APP_COMPANY,
	},
});

function getCookie(name, defaultValue) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
	return defaultValue;
}

api.interceptors.request.use(
	(config) => {
		const customer_id = getCookie('mage_customer_id'); //1187 intempo; 3292 fipell;
		const controller = new AbortController();
		if (customer_id) {
			config.headers['X-APP-KEY'] = CryptoJS.AES.encrypt(customer_id.toString(), API_KEY).toString();
		} else {
			controller.abort();
		}

		return {
			...config,
			signal: controller.signal
		};
	},
	error => Promise.reject(error),
);


api.interceptors.response.use(
	response => response,
	(error) => {
		console.log(error)
		if (axios.isCancel(error)) {
			return new Promise(() => {});
		}
		if (error.response && error.response.status === 401) {
			if (process.env.VUE_APP_ENV !== 'local') {
				window.location.href = process.env.VUE_APP_STORE_URL
			}
		}
		return Promise.reject(error);
	},
);

export default api;
