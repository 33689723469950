import {
  createRouter,
  createWebHashHistory
} from "vue-router";

const routes = [{
    path: "/",
    name: "home",
    component: function () {
      return import("../views/OrdersView.vue");
    },
  },
  {
    path: "/documenti",
    name: "documenti",
    props: (route) => ({
      NUMREG_CO99: route.query.NUMREG_CO99
    }),
    component: function () {
      return import("../views/DocumentsView.vue");
    },
  },
  {
    path: "/riepilogo",
    name: "riepilogo",
    component: function () {
      return import("../views/SummaryView.vue");
    },
  },
];
console.log("RMA is", process.env.VUE_APP_RMA);
if (process.env.VUE_APP_RMA === "true" || process.env.VUE_APP_RMA === true) {
  routes.push({
    path: "/assistenza", ///:doc/:art
    name: "assistenza",
    component: function () {
      return import("../views/AssistanceView.vue");
    },
  });
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;