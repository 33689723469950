import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import Toaster from '@meforma/vue-toaster'
import VueCookies from 'vue-cookies';
import { OhVueIcon, addIcons } from "oh-vue-icons";
import { HiUser, RiFileUserLine, BiShopWindow, MdEmail, BiTagFill, MdPlace, RiAlertFill, CoSend } from "oh-vue-icons/icons";
import VueBasicAlert from 'vue-basic-alert';

addIcons(HiUser, RiFileUserLine, BiShopWindow, MdEmail, BiTagFill, MdPlace, RiAlertFill, CoSend);


createApp(App).use(store).use(router).use(Toaster).use(VueCookies).use(VueBasicAlert).component("v-icon", OhVueIcon).mount('#app')
