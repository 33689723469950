<template>
  <div :class="bodyClass">
    <nav class="navbar fixed-top navbar-expand-lg bg-body-tertiary navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand mr-auto" :href="link">
          <img :src="logo" title="Intempo-Distributions" alt="Intempo-Distributions" height="50"
            class="d-inline-block align-top" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
          aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarText">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link">I miei Ordini</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/documenti" class="nav-link">Fatture e Dtt</router-link>
            </li>
            <li class="nav-item dropdown" v-if="rma === 'true' || assistenza === 'true'">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                Assistenza
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <router-link v-if="rma" to="/assistenza?type=reso" class="m-0 dropdown-item">
                  Richiesta Reso
                </router-link>
                <router-link v-if="assistenza" to="/assistenza?type=assistenza" class="m-0 dropdown-item">
                  Richiesta Assistenza
                </router-link>
              </div>
            </li>
            <li class="nav-item">
              <router-link to="/riepilogo" class="nav-link">Riepilogo Documenti Fiscali</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <br>
    <br>
    <br>
    <br>
    <div style="text-align: right; padding: 20px">
      <a :href="link" class="home">Torna alla home</a>
    </div>
    <router-view />
    <footer class="bd-footer bg-body-tertiary">
      <div class="container text-body-secondary py-3" style="font-size: 12px">
        {{ footer }}
      </div>
    </footer>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "App",
  data() {
    return {
      logo: process.env.VUE_APP_LOGO,
      bodyClass: process.env.VUE_APP_STORE,
      link: process.env.VUE_APP_STORE_URL,
      footer: process.env.VUE_APP_FOOTER,
      rma: process.env.VUE_APP_RMA,
      assistenza: process.env.VUE_APP_ASSISTENZA,
    };
  },
  mounted() {
    const name = "mage_customer_id";
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length !== 2) {
      console.log("Missing customer id cookie");
      alert("Non è stato possibile recuperare il tuo account, assicurati di aver abilitato i cookie. Se il problema persiste contatta il nostro servizio clienti.");
      if (process.env.VUE_APP_ENV !== "local") {
        window.location.href = process.env.VUE_APP_STORE_URL;
      }
    }
  },
});
</script>
<style>
.home {
  text-decoration: none;
  padding: 10px;
  margin-top: 20px;
  margin-right: 20px;
  background-color: #266782;
  color: white;
  text-align: right;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
}

@font-face {
  font-family: "Gill Sans";
  src: local("Gill Sans"), url(./fonts/GillSans/GillSans.otf) format("truetype");
}

@font-face {
  font-family: "Gill Sans Bold";
  src: local("Gill Sans Bold"), url(./fonts/GillSans/GillSansBold.otf) format("truetype");
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#app .fipell {
  font-family: Helvetica, sans-serif;
}

#app .intempo {
  font-family: Gill Sans, sans-serif;
}

#app .intempo h2,
strong {
  font-family: "Gill Sans Bold", sans-serif;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: gray;
  text-decoration: none;
  margin: 5px;
}

nav a.router-link-exact-active {
  color: black;
}
</style>
